import head from './img/headimg.png';
import bodyimg from './img/bodyimg.png';
import footerimg from './img/boxes.png';
import bodyimgm from './img/bodyimgmobile.png';
import './App.css';

function App() {
  const handleClick = () => {
    window.open('mailto:info@avocadogroup.com?subject=Avocado%20Inquiry&body=Hello%20Avocado%20Group,');
  };
  const handleClick2 = () => {
    window.open("https://www.notion.so/avocadogroup/6e744a02db354422a8bf8d38742bb404?v=7d858d3397f74247bef5bf6eafab47f6&pvs=4");
  };
  return (
    <div className='App'>
      <header className="App-header">
        <h2 className='header-ticker-name'>AVOCADOGROUP ©</h2>
        <button className='Header-button' onClick={handleClick}>CONTACT
        </button>
      </header>
          
      <body className="App-body">
        <hr
          style={{
            background:"black",
            height: '2px',
            width: '92%',
            marginTop: '-1px',
            border: 'none',
          }} />
        <img src={head} className="App-logo" alt='head logo' />
        <img src={bodyimg} className="img-responsive" alt='future fueled creative uc' />
        <img src={bodyimgm} className="img-mobile-image" alt='future fueled creative uc' />
        <p className='Body-content'>A thriving community of visionary individuals united by a shared passion for shaping the future of creative services. We specialize in branding and community building, crafting compelling brand identities, and delivering innovative packaging solutions.</p>
        <button className='Body-button' onClick={handleClick2}>MORE INFO
        </button>
        <img src={footerimg} className='App-footerlogo' alt='boxes'/>
      </body>
      <footer className="App-footer">© 2019-2023 The Avocado Group, LLC</footer>
    </div>
  );
}

export default App;
